$primary-color: #3498db;
$secondary-color: #2c3e50;
$text-color: #333;
$background-color: #f5f5f5;

.color-palette-generator {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;

  h1 {
    font-size: 2rem;
    color: $secondary-color;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .generate-button {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: $primary-color;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken($primary-color, 10%);
    }

    .icon {
      margin-right: 10px;
    }
  }

  .color-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
  }

  .color-box {
    position: relative;
    aspect-ratio: 1 / 1;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }

    .copy-button {
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0);
      color: white;
      opacity: 0;
      transition: opacity 0.3s ease, background-color 0.3s ease;

      &:hover {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .alert {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2ecc71;
    color: white;
    padding: 10px;
    border-radius: 5px;
    margin-top: 1rem;

    .icon {
      margin-right: 10px;
    }

    p {
      margin: 0;
    }
  }
}

// Media query for responsiveness
@media (max-width: 768px) {
  .color-palette-generator {
    .color-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}