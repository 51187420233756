// Variables
$grid-gutter-width: 1.5rem;
$container-max-widths: (
  sm: 540px,
  // Corresponding to min-width 576px
  md: 720px,
  // Corresponding to min-width 768px
  lg: 960px,
  // Corresponding to min-width 992px
  xl: 1140px,
  // Corresponding to min-width 1200px
  xxl: 1320px // Corresponding to min-width 1400px,,,,,
);

$breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

// Container
.container,
.container-fluid {
  width: 100%;
  padding-right: calc($grid-gutter-width / 2);
  padding-left: calc($grid-gutter-width / 2);
  margin-right: auto;
  margin-left: auto;
}

// Fixed Width Containers (Responsive)
@each $size, $max-width in $container-max-widths {
  @media (min-width: map-get($breakpoints, $size)) {
    .container {
      max-width: $max-width;
    }
  }
}

// Fluid Container
.container-fluid {
  width: 100%;
  max-width: 100%;
}

// Row
.row {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  margin-right: calc($grid-gutter-width / 2);
  margin-left: calc($grid-gutter-width / 2);

  > * {
    flex-shrink: 0;
    margin-top: var($grid-gutter-width);
    max-width: 100%;
    padding-left: calc($grid-gutter-width * 0.5);
    padding-right: calc($grid-gutter-width * 0.5);
    width: 100%;
  }
}

// Column
.col {
  flex: 1;
  padding-right: calc($grid-gutter-width / 2);
  padding-left: calc($grid-gutter-width / 2);
}

// Column Auto and Full-Width Utilities
.col-auto {
  flex: 0 0 auto;
  width: auto;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

// Column Widths (1 to 12)
@function percentage($num) {
  @return calc(100% / 12) * $num;
}

@each $size, $breakpoint in $breakpoints {
  @media (min-width: $breakpoint) {
    @for $i from 1 through 12 {
      .col-#{$size}-#{$i} {
        flex: 0 0 percentage($i);
        max-width: percentage($i);
      }
    }
    .col-#{$size}-auto {
      flex: 0 0 auto;
      max-width: 100%;
    }
  }
}

// Alignment Utilities
.row.justify-content-center {
  justify-content: center;
}
.row.justify-content-start {
  justify-content: flex-start;
}
.row.justify-content-end {
  justify-content: flex-end;
}
.row.justify-content-between {
  justify-content: space-between;
}
.row.justify-content-around {
  justify-content: space-around;
}

.row.align-items-center {
  align-items: center;
}
.row.align-items-start {
  align-items: flex-start;
}
.row.align-items-end {
  align-items: flex-end;
}
