$color-code-background: #191d2b;
$color-code-color: #f8f8f2;

code {
  background-color: $color-code-background;
  color: $color-code-color;
  border-radius: 0.3rem;
  padding: 4px 5px 5px;
  white-space: nowrap;
}

pre code {
  white-space: inherit;
}

pre {
  background-color: $color-code-background;
  padding: 5px;
  border-radius: 0.3em;
}
