// stylelint-disable declaration-no-important, selector-no-qualifying-type, property-no-vendor-prefix

// Reboot
//
// Normalization of HTML elements, manually forked from Normalize.css to remove
// styles targeting irrelevant browsers while applying new styles.
// Normalize is licensed MIT. https://github.com/necolas/normalize.css

// Document
// Change to `box-sizing: border-box` so that `width` is not affected by `padding` or `border`.
*,
*::before,
*::after {
  box-sizing: border-box;
}

// Root
// Allow setting root font size and smooth scrolling if enabled.
:root {
  font-size: $fz-body;
}

// Body
body {
  margin: 0;
  font-family: $ff-body;
  font-size: $fz-body;
  font-weight: 400;
  line-height: 1.5;
  color: $color-body;
  background-color: $color-bg-body;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

// Heading styles
%heading {
  margin-top: 0;
  margin-bottom: $spacer;
  font-family: $ff-heading;
  color: $color-heading;
}

h1 {
  @extend %heading;
  font-size: 2.5rem;
}

h2 {
  @extend %heading;
  font-size: 2rem;
}

h3 {
  @extend %heading;
  font-size: 1.75rem;
}

h4 {
  @extend %heading;
  font-size: 1.5rem;
}

h5 {
  @extend %heading;
  font-size: 1.25rem;
}

h6 {
  @extend %heading;
  font-size: 1rem;
}

// Reset margins on paragraphs
p {
  margin-top: 0;
  margin-bottom: $spacer;
}

// Abbreviations
abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

// Address
address {
  margin-bottom: $spacer;
  font-style: normal;
  line-height: inherit;
}

// Lists
ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: $spacer;
}

// Strong
b,
strong {
  font-weight: 700;
}

// Small text
small {
  font-size: 0.875rem;
}

// Marked text
mark {
  padding: 0.2em;
  background-color: $light-color-heading;
}

// Links
a {
  color: $color-theme;
  text-decoration: underline;

  &:hover {
    color: darken($color-theme, 10%);
    text-decoration: none;
  }
}

// Code styles
pre,
code,
kbd,
samp {
  font-family: $ff-code;
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: $spacer;
  overflow: auto;
  font-size: 0.875rem;
  color: $color-secondary;
  background-color: $color-bg-dark;
}

code {
  font-size: 0.875rem;
  color: $color-theme;
}

// Button styles
button,
input,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border-radius: 0;
}

button {
  cursor: pointer;

  &:focus:not(:focus-visible) {
    outline: 0;
  }
}

// Forms
label {
  display: inline-block;
}

textarea {
  resize: vertical;
}

// Fieldset and legend
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: $spacer;
  font-size: 1rem;
  font-weight: bold;
  line-height: inherit;

  + * {
    clear: left;
  }
}

// Table styles
table {
  caption-side: bottom;
  border-collapse: collapse;
}

th {
  font-weight: bold;
  text-align: inherit;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: $color-body;
  text-align: left;
}

// Hidden attribute
[hidden] {
  display: none !important;
}
