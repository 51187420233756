table {
  display: block;
  width: 100%;
  width: max-content;
  max-width: 100%;
  overflow: auto;
  margin-bottom: $spacer;
  thead {
    tr {
      background-color: $color-bg-body;
    }
  }
  th {
    text-align: center;
  }
  tbody {
    tr {
      background-color: $color-code-background;
      &:nth-child(even) {
        background-color: $color-bg-body;
      }
    }
  }
  th,
  td {
    padding: 6px 13px;
    border: 1px solid $color-border;
  }
}
