.toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

#day-night-toggle {
  display: none;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 100px;
  border-radius: 50px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .scene {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 1s ease;
  }

  &.day .scene {
    background-color: #93c5fd; // light blue
  }

  &.night .scene {
    background-color: #312e81; // dark indigo
  }

  .celestial-body {
    position: absolute;
    transition: all 1s ease;

    .body {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    .aura {
      position: absolute;
      top: -4px;
      left: -4px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      opacity: 0.5;
      filter: blur(4px);
    }
  }

  &.day .celestial-body {
    top: 10px;
    right: 20px;

    .body {
      background-color: #fde047; // yellow
    }

    .aura {
      background-color: #fef08a; // light yellow
      animation: pulse 2s infinite;
    }
  }

  &.night .celestial-body {
    top: 10px;
    left: 20px;

    .body {
      background-color: #d1d5db; // gray
    }

    .aura {
      background-color: #9ca3af; // light gray
      opacity: 0.2;
    }
  }

  .star {
    position: absolute;
    background-color: white;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  &.night .star {
    opacity: 1;
    animation: twinkle 4s ease-in-out infinite;
  }

  .mountains {
    position: absolute;
    bottom: 0;
    width: 100%;

    svg {
      width: 100%;
      height: auto;
    }

    path {
      transition: fill 1s ease;
    }
  }

  &.day .mountains path {
    fill: #4b5563; // gray
  }

  &.night .mountains path {
    fill: #1f2937; // dark gray
  }

  .toggle-handle {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 90px;
    height: 90px;
    background-color: rgb(216, 216, 216);
    border-radius: 45px;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    .icon {
      font-size: 40px;
    }
  }

  &.night .toggle-handle {
    transform: translateX(100px);
  }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

@keyframes twinkle {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}