.skeleton-loader {
  background-color: #e0e0e0;
  position: relative;
  overflow: hidden;

  .shimmer-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: shimmer 2s infinite;
  }

  .shimmer {
    width: 50%;
    height: 100%;
    background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    );
    transform: skewX(-20deg);
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-150%);
  }
  100% {
    transform: translateX(150%);
  }
}

.skeleton-article,
.skeleton-profile {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.skeleton-profile {
  .profile-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .profile-info {
      flex-grow: 1;
      margin-left: 20px;
    }
  }
}
