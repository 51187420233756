.banded-payment-slider {
  max-width: 48rem;
  margin: 0 auto;
  width: 100%;
  padding: 1rem;
  padding-top: 5rem;

  .slider {
    position: relative;
    height: 1rem;
    background: linear-gradient(to right, #80cbb4, #009768);

    .band {
      position: absolute;
      height: 100%;
    }

    .marker {
      position: absolute;
      display: flex;
      height: 3rem;
      width: 1rem;
      cursor: move;
      align-items: center;
      justify-content: center;
      border-radius: 0.25rem;
      border: 2px solid #4a5568;
      background-color: #2d3748;
      top: calc(50% - 1.5rem);
      z-index: 10;

      &:hover .tooltip {
        opacity: 1;
        visibility: visible;
      }

      .tooltip {
        position: absolute;
        top: -4rem;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s, visibility 0.3s;
        z-index: 20;

        .tooltip-content {
          position: relative;
          border-radius: 0.375rem;
          border: 1px solid #000;
          background-color: #fff;
          padding: 0.25rem 0.5rem;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          white-space: nowrap;
          text-align: center;

          font-size: 0.75rem;
          font-weight: 700;
          color: #000;

          &::after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: -0.375rem;
            height: 0.75rem;
            width: 0.75rem;
            transform: translateX(-50%) rotate(45deg);
            border-right: 1px solid #000;
            border-bottom: 1px solid #000;
            background-color: #fff;
          }
        }
      }
    }

    .tick {
      position: absolute;
      height: 1.5rem;
      width: 2px;
      background-color: #4a5568;
      top: -0.2rem;

      .tick-label {
        position: absolute;
        bottom: -1rem;
        font-size: 0.75rem;
        font-weight: 700;
        color: #000;

        &:last-child {
          right: -2rem;
        }
      }
    }

    .clickable-area {
      position: absolute;
      height: 1rem;
      width: 100%;
      cursor: pointer;
    }
  }

  .band-inputs {
    margin-top: 1rem;
    display: flex;
    justify-content: space-around;

    .band-input {
      display: flex;
      flex-direction: column;
      align-items: center;

      label {
        margin-right: 0.5rem;
        font-weight: 700;
      }

      .input-group {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        input {
          width: 5rem;
          text-align: right;
        }

        button {
          color: #e53e3e;
          border: none;
          padding: 3px;

          &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
          }
        }
      }
    }
  }
}
