// LondonSkyline.scss

@mixin building($width, $height, $color) {
  width: $width;
  height: $height;
  position: absolute;
  // background-color: $color;
  bottom: 0;
}

.london-skyline {
  width: 100%;
  height: 50vh;
  overflow: hidden;
  position: relative;
  transition: all 2s ease;

  &.dawn {
    background: linear-gradient(to bottom, #ff6b6b, #4ecdc4);
  }
  &.day {
    background: linear-gradient(to bottom, #56ccf2, #2f80ed);
  }
  &.dusk {
    background: linear-gradient(to bottom, #ff8c00, #ff512f);
  }
  &.night {
    background: linear-gradient(to bottom, #2c3e50, #000000);
  }

  .sky {
    width: 100%;
    height: 70%;
    position: relative;
    overflow: hidden;
  }

  .sun,
  .moon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    transition: all 1s linear;
  }

  .sun {
    background: #ffd700;
    box-shadow: 0 0 20px #ffd700;
  }

  .moon {
    background: #f4f4f4;
    box-shadow: 0 0 20px #f4f4f4;
  }

  .stars {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: opacity 2s ease;

    &::before {
      content: "";
      width: 2px;
      height: 2px;
      background: white;
      box-shadow: 0 0 10px 1px white;
      position: absolute;
      top: 50%;
      left: 50%;
      animation: twinkle 4s infinite;
    }
  }

  &.night .stars {
    opacity: 1;
  }

  .clouds {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: opacity 2s ease;

    .cloud {
      width: 200px;
      height: 60px;
      background: #fff;
      border-radius: 200px;
      position: absolute;
      animation: cloud-move 20s linear infinite;

      &:nth-child(1) {
        top: 20%;
        left: -10%;
        animation-delay: 0s;
      }
      &:nth-child(2) {
        top: 40%;
        left: -20%;
        animation-delay: -5s;
      }
      &:nth-child(3) {
        top: 60%;
        left: -15%;
        animation-delay: -10s;
      }

      &::before,
      &::after {
        content: "";
        background: #fff;
        position: absolute;
      }

      &::before {
        width: 100px;
        height: 100px;
        top: -50px;
        left: 50px;
        border-radius: 100px;
      }

      &::after {
        width: 80px;
        height: 80px;
        top: -35px;
        right: 50px;
        border-radius: 100px;
      }
    }
  }

  &.cloudy .clouds,
  &.rainy .clouds {
    opacity: 1;
  }

  .rain {
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 100%
    );
    animation: rain 0.3s linear infinite;
    opacity: 0;
    transition: opacity 2s ease;
  }

  &.rainy .rain {
    opacity: 0.5;
  }

  .cityscape {
    width: 100%;
    height: 30%;
    position: absolute;
    bottom: 15%;
  }

  // Color variables
  $stone-color: #cfc5a5; // Light beige for the stonework
  $stone-dark: #a39770; // Darker beige for shadows and details
  $clock-face: #344c5b; // Dark blue-gray for clock faces
  $clock-detail: #b29e6c; // Gold color for clock details
  $hand-color: #2a2a2a; // Dark gray for hour and minute hands
  $second-hand: #b22222; // Dark red for second hand

  .big-ben {
    @include building(60px, 300px, #c4b454);
    left: 20%;

    .base {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% + 40px);
      height: 20px;
      background-color: $stone-color;
      border-radius: 10px;
      border: 2px solid $stone-dark;
    }

    .antenna {
      position: absolute;
      top: calc(0% - 30px);
      left: 50%;
      transform: translateX(-50%);
      width: 4px;
      height: 30px;
      background-color: $stone-dark;
    }

    .top-line {
      position: absolute;
      top: 0%;
      left: 50%;
      transform: translateX(-50%);
      width: 60px;
      height: 6px;
      background-color: $stone-dark;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: calc(100% - 30px);
        width: 4px;
        height: 40px;
        background-color: $stone-dark;
      }

      &::before {
        left: -10px;
        transform: rotate(30deg);
      }

      &::after {
        right: -10px;
        transform: rotate(-30deg);
      }
    }

    .tower,
    .tower2 {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background-color: $stone-color;
      border: 4px solid $stone-dark;
    }

    .tower {
      top: 0%;
      width: 40px;
      height: 30px;
    }

    .tower2 {
      top: 10%;
      width: 80px;
      height: 40px;
    }

    .top-base {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 56px;
      height: 20px;
      background-color: $stone-color;
      border: 4px solid $stone-dark;
    }

    .clock {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% + 8px);
      height: 100px;
      background-color: $clock-face;
      border: 4px solid $stone-dark;

      .clock-band {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        height: 30px;
        border-top: 4px solid $clock-detail;
        border-bottom: 4px solid $clock-detail;
      }

      .clock-square {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80px;
        height: 80px;
        background-color: $clock-face;
        border: 4px solid $clock-detail;
      }

      .clock-circle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70px;
        height: 70px;
        background-color: white;
        border: 4px solid $clock-detail;
        border-radius: 50%;
      }

      .hour,
      .minute {
        position: absolute;
        bottom: 50%;
        left: 50%;
        transform-origin: 50% 100%;
        background-color: $hand-color;
      }

      .hour {
        width: 4px;
        height: 20px;
        margin-left: -2px;
      }

      .minute {
        width: 3px;
        height: 25px;
        margin-left: -1.5px;
      }

      .seconds {
        position: absolute;
        bottom: 50%;
        left: 50%;
        transform-origin: 50% 100%;
        width: 2px;
        height: 30px;
        margin-left: -1px;
        background-color: $second-hand;
      }
    }

    .pillar {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      width: 80px;
      height: 300px;
      background-color: $stone-color;
      border: 4px solid $stone-dark;

      .p1,
      .p2,
      .p3,
      .p4 {
        position: absolute;
        left: 0;
        width: 100%;
        height: 10px;
        background-color: $stone-dark;
      }

      .p1 {
        top: 0;
      }
      .p2 {
        top: 25%;
      }
      .p3 {
        top: 50%;
      }
      .p4 {
        top: 75%;
      }

      .pl1,
      .pl2,
      .pm1,
      .pm2,
      .pm3 {
        position: absolute;
        bottom: 0;
        width: 4px;
        background-color: $stone-dark;
      }

      .pl1 {
        left: 10%;
        height: 100%;
      }
      .pl2 {
        right: 10%;
        height: 100%;
      }
      .pm1 {
        left: 30%;
        height: 70%;
      }
      .pm2 {
        left: 50%;
        height: 70%;
      }
      .pm3 {
        right: 30%;
        height: 70%;
      }
    }

    .rails {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .r {
        position: absolute;
        top: 50%;
        width: 2px;
        height: 80%;
        background-color: $stone-dark;
        transform: translateY(-50%);
      }

      .r1 {
        left: 20%;
      }
      .r2 {
        left: 40%;
      }
      .r3 {
        left: 60%;
      }
      .r4 {
        left: 80%;
      }
    }
  }

  .london-eye {
    @include building(150px, 150px, transparent);
    right: 15%;
    .stand {
      fill: #666;
      margin-left: -7px;
    }

    .wheel {
      fill: #444;
    }

    .grouped {
      animation: spin 100s infinite linear;
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    svg {
      width: 100%;
      margin: auto;
      display: block;
    }
  }

  .shard {
    @include building(80px, 250px, #87ceeb);
    right: 30%;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }

  .gherkin {
    @include building(60px, 180px, #006400);
    left: 40%;
    border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
  }

  .tower-bridge {
    @include building(200px, 100px, #c0c0c0);
    left: 50%;
    transform: translateX(-50%);

    .bridge-top {
      width: 100%;
      height: 60px;
      background: #a9a9a9;
      position: absolute;
      top: 0;
    }

    .bridge-bottom {
      width: 100%;
      height: 20px;
      background: #808080;
      position: absolute;
      bottom: 0;
    }
  }

  .thames {
    width: 100%;
    height: 15%;
    background: #1e90ff;
    position: absolute;
    bottom: 0;
    animation: water-move 10s linear infinite;
  }

  .foreground {
    width: 100%;
    height: 5%;
    background: #228b22;
    position: absolute;
    bottom: 0;
  }
}

@keyframes sun-move {
  0% {
    transform: translateX(-50px) translateY(300px);
  }
  25% {
    transform: translateX(calc(50vw - 40px)) translateY(-40px);
  }
  50% {
    transform: translateX(calc(100vw + 50px)) translateY(300px);
  }
  75%,
  100% {
    transform: translateX(calc(50vw - 40px)) translateY(calc(100vh + 40px));
  }
}

@keyframes moon-move {
  0%,
  25% {
    transform: translateX(calc(50vw - 40px)) translateY(calc(100vh + 40px));
  }
  50% {
    transform: translateX(-50px) translateY(300px);
  }
  75% {
    transform: translateX(calc(50vw - 40px)) translateY(-40px);
  }
  100% {
    transform: translateX(calc(100vw + 50px)) translateY(300px);
  }
}

@keyframes cloud-move {
  0% {
    transform: translateX(-200px);
  }
  100% {
    transform: translateX(calc(100vw + 200px));
  }
}

@keyframes rain {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 20px;
  }
}

@keyframes rotate {
  0% {
    transform: translateX(-50%) rotate(0deg);
  }
  100% {
    transform: translateX(-50%) rotate(360deg);
  }
}

@keyframes water-move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@keyframes twinkle {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
