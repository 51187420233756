.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: lighten($color-bg-body, 5);
  border: 1px solid $color-border;
  color: #fff;
  padding: 1rem;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  align-items: center;
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  gap: 1rem;

  .cookie-consent__message {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
  }

  .cookie-consent__buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
}
